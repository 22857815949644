import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, ClientBodyTest } from '../../../../types/types';
import ClientBodyTestsService from '../../../../services/bodyTests/ClientBodyTestsService';

interface GetClientBodyTestProps {
    client: Client;
    bodyTests: ClientBodyTest[];
    setBodyTests: React.Dispatch<React.SetStateAction<ClientBodyTest[]>>;
}

const GetClientBodyTest: React.FC<GetClientBodyTestProps> = ({ client, bodyTests, setBodyTests }) => {
    const { t } = useTranslation('global');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        ClientBodyTestsService.getClientBodyTest(client.id)
            .then(response => {
                setBodyTests(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Błąd podczas pobierania danych:', error);
                setLoading(false);
            });
    }, [client.id, setBodyTests]); // <-- Dodano zależność

    if (loading) {
        return <div>{t('loading')}</div>;
    }

    return (
        <div>
            <h2>{t('clients.show_done_body_tests')}</h2>
            {bodyTests.length === 0 ? (
                <p>{t('clients.no_body_tests_yet')}</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th className="column-large">{t("table.name")}</th>
                            <th className="column-large">{t("table.description")}</th>
                            <th className="column-large">{t("table.result")}</th>
                            <th className="column-large">{t("table.purpose")}</th>
                            <th className="column-large">{t("table.options")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bodyTests.map(test => (
                            <tr key={test.id} style={{ borderBottom: '1px solid #ddd' }}>
                                <td className="column-large">{test.name}</td>
                                <td className="column-large">{test.description}</td>
                                <td className="column-large">{test.result}</td>
                                <td className="column-large">{test.purpose}</td>
                                <td className="column-large">{test.dict}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default GetClientBodyTest;
